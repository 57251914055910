<template>
  <DataContainer
    :project-id="projectId"
    :dimension-id="dimensionId"
    :manifest="manifest"
    class="data-single"
  >
    <LeafManifestChart v-bind="payload" @query="ended = $event" />

    <Progress v-if="!ended" variant="odd" with-overlay />
  </DataContainer>
</template>

<script>
import { db } from '@/config/firebase';
import { LeafManifest } from '@odd-industries/odd-charting';
import { WithTime } from '@/mixins';
import mixin from './mixin';
import { functions } from '@/helpers';

// components
import DataContainer from './DataContainer.vue';
import { LeafManifestChart } from '@odd-industries/odd-charting-components';

const METRICS_COLLECTION_REF = db.collection('Metrics');
const REGISTERS_COLLECTION_REF = db.collection('Registers');

const fetchRegisterSignedURI = async file => {
  const match = /(.+)\/(.+)\/(.+)\/(.+)\/(.+)\.jpg/.exec(file.filename);

  if (match) {
    const BUCKET = 'process-bucket-cmdic';

    const [, serviceId, year, month, day, timestamp] = match;
    const filename = `${serviceId}/${year}-${month}-${day}/THUMBNAIL/${timestamp}-480.jpg`;

    const uri = await functions.fetchFileSignedURI({
      bucket: BUCKET,
      filename,
      outputFilename: undefined,
    });

    return uri;
  }

  return '';
};

export default {
  name: 'DataSingle',
  components: { DataContainer, LeafManifestChart },
  mixins: [WithTime, mixin],
  data() {
    return {
      ended: false,
    };
  },
  computed: {
    payload() {
      return {
        name: 'foo',

        metricsRef: METRICS_COLLECTION_REF,
        manifest: new LeafManifest(this.manifest),

        dateRange: this.dateRange,
        granularity: 2,
        limitDateRange: this.limitDateRange,

        formatters: {
          type: cell => {
            const value = cell.getValue();

            return this.$t(`c.events-table.types.${value}`);
          },
          registersURIs: cell => {
            const uris = cell.getValue();

            return _.reduce(
              uris,
              (el, uri) => {
                el += `
                  <a href="${uri}" target="_blank">
                    <div
                      style="background-image: url(${uri})"
                      class="events-table__image">
                    </div>
                  </a>
                `;

                return el;
              },
              ''
            );
          },
        },

        async fetchRegisterURIById(registerId) {
          return fetchRegisterSignedURI(
            (await REGISTERS_COLLECTION_REF.doc(registerId).get()).data()
          );
        },
      };
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.data-single
  overflow-x: auto
</style>
