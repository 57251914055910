import { WITH_MANIFEST_PROPS } from './constants';

export default {
  props: {
    projectId: { type: String, required: true },
    dimensionId: { type: String, required: true },

    ...WITH_MANIFEST_PROPS,
  },
};
