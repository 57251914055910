const PRESET_TO_DATE_RANGE = Object.freeze([
  // today
  ({ pivot }) => ({
    from: moment(pivot).startOf('day'),
    to: moment(pivot).endOf('day'),
  }),

  // yesterday
  ({ pivot }) => {
    const yesterday = moment(pivot).subtract(1, 'days');

    return {
      from: moment(yesterday).startOf('day'),
      to: moment(yesterday).endOf('day'),
    };
  },

  // this-week
  ({ pivot }) => ({
    from: moment(pivot).startOf('isoWeek'),
    to: moment(pivot).endOf('day'),
  }),

  // this-month
  ({ pivot }) => ({
    from: moment(pivot).startOf('month'),
    to: moment(pivot).endOf('day'),
  }),

  // this-year
  ({ pivot }) => ({
    from: moment(pivot).startOf('year'),
    to: moment(pivot).endOf('day'),
  }),

  // last-week
  ({ pivot }) => ({
    from: moment(pivot)
      .subtract(6, 'days')
      .startOf('day'),
    to: moment(pivot).endOf('day'),
  }),

  // last-month
  ({ pivot }) => ({
    from: moment(pivot)
      .subtract(29, 'days')
      .startOf('day'),
    to: moment(pivot).endOf('day'),
  }),

  // last-year
  ({ pivot }) => ({
    from: moment(pivot)
      .subtract(12, 'months')
      .startOf('day'),
    to: moment(pivot).endOf('day'),
  }),

  // beginning-of-time
  ({ minDate, maxDate }) => ({
    from: moment(minDate).startOf('day'),
    to: moment(maxDate).endOf('day'),
  }),
]);

export const presetToDateRange = (preset, payload = {}) => {
  if (!payload.pivot) payload.pivot = moment();

  return PRESET_TO_DATE_RANGE[preset](payload);
};
