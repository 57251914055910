<template functional>
  <div
    :key="data.key"
    :class="[
      'message',
      `v-spacing-${props.slim ? 2 : 3}`,
      { 'message--slim': props.slim },
    ]"
  >
    <icon v-if="props.icon" :color="props.iconColor">{{ props.icon }}</icon>

    <t
      v-if="props.title"
      :color="props.titleColor"
      lg
      bold
      center
      class="message__title"
    >
      {{ props.title }}
    </t>
    <t
      v-if="props.message"
      :color="props.messageColor"
      sm
      medium
      center
      class="message__message"
    >
      {{ props.message }}
    </t>
  </div>
</template>

<script>
export default {
  name: 'Message',
  props: {
    icon: { type: String, default: undefined },
    title: { type: String, default: undefined },
    message: { type: String, default: undefined },

    iconColor: { type: String, default: 'gray-1' },
    titleColor: { type: String, default: 'secondary' },
    messageColor: { type: String, default: 'gray-2' },

    slim: { type: Boolean, default: false },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$icon-max-width: 96px
$icon-min-width: 64px

$icon-width: 50%
$slim-icon-width: 32px

.message
  +d-flex-c(center, center)
  +h-margin(auto)

.message > .e-icon
  max-width: $icon-max-width
  min-width: $icon-min-width
  width: $icon-width
  height: auto

// - slim
.message--slim
  & > .e-icon
    width: $slim-icon-width

  .message__title
    --f-size: #{$f-size-md}
    --f-weight: #{$f-weight-semibold}

  .message__message
    --f-size: #{$f-size-xs}
    --f-weight: #{$f-weight-regular}
</style>
