<script>
import { parseI18nText } from '@/helpers';
import {
  TITLE_KEY,
  TITLE_PARAMETERS_KEY,
  WITH_MANIFEST_PROPS,
} from './constants';

export default {
  name: 'DataTitle',
  functional: true,
  props: { ...WITH_MANIFEST_PROPS },

  render(createElement, ctx) {
    const { manifest } = ctx.props;
    const { staticStyle, staticClass } = ctx.data;

    const externalBind = manifest[TITLE_PARAMETERS_KEY];
    const internalBind = ctx.data.attrs;
    const bind = Object.assign({}, internalBind, externalBind);

    return createElement(
      't',
      { props: bind, class: staticClass, style: staticStyle },
      [parseI18nText(manifest[TITLE_KEY])]
    );
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
</style>
