<template>
  <div class="openable-card" @click="isOpen = !isOpen">
    <slot name="activator" />

    <template v-if="isOpen">
      <portal v-if="havePortal" :to="to">
        <div v-click-outside="close" :class="bodyClassName_">
          <slot name="body" :close="close" />
        </div>
      </portal>

      <transition v-else name="translate-y-quickly" appear>
        <div v-click-outside="close" :class="bodyClassName_" @click.stop>
          <slot name="body" :close="close" />
        </div>
      </transition>
    </template>
  </div>
</template>

<script>
export default {
  name: 'OpenableCard',
  props: {
    to: {
      type: String,
      default: undefined,
    },

    bodyClassName: {
      type: Array,
      default: undefined,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    havePortal() {
      return !_.isUndefined(this.to);
    },

    bodyClassName_() {
      const bodyClassName_ = ['card'];
      if (this.bodyClassName) bodyClassName_.push(...this.bodyClassName);

      return bodyClassName_;
    },
  },
  methods: {
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.openable-card
  position: relative

.openable-card > .card
  +p-absolute-top-right(#{$z-index-overlay + 1}, 0)
</style>
