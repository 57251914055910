<template>
  <transition name="text-fade" mode="out-in">
    <t :key="key" sm medium class="date-range-picker__date-range">
      <t color="primary" semibold>{{ $t('c.date-range-picker.from') }}</t>
      {{ dateRange.from | asDate }}

      <span class="mh-2">/</span>

      <template v-if="dateRange.to">
        <t color="primary" semibold>
          {{ $t('c.date-range-picker.to') }}
        </t>
        {{ dateRange.to | asDate }}
      </template>
    </t>
  </transition>
</template>

<script>
export default {
  name: 'DateRangePickerDateRange',
  props: {
    dateRange: { type: Object, required: true },
  },
  computed: {
    key() {
      const { asDate } = this.$time.format;
      const { from, to } = this.dateRange;

      return from && !to
        ? `${asDate(from)}`
        : `${asDate(from)} / ${asDate(to)}`;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass
@import @/assets/stylesheets/lib/index.sass

// base
.date-range-picker__date-range
  +text-overflow(false, auto)
</style>
