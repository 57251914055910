<template>
  <div class="data-container card">
    <div class="data-container__header">
      <div class="pv-3 ph-4">
        <DataTitle :manifest="manifest" sm semibold capitalize />

        <div class="spacer" />

        <t color="gray-2" xs>{{ dateRange }}</t>
      </div>

      <slot name="header" />
    </div>

    <div class="data-container__body">
      <portal-target :name="portalTargetName" />

      <portal :to="fullScreen ? 'app' : portalTargetName">
        <div
          :class="{
            'data-container__full-screen-body b-radius-3 elevation-10': fullScreen,
          }"
        >
          <div class="data-container__header">
            <template v-if="fullScreen">
              <div class="pv-3 pr-3 pl-4">
                <DataTitle :manifest="manifest" sm semibold capitalize />

                <div class="spacer" />

                <t color="gray-2" xs>{{ dateRange }}</t>

                <EButton md icon class="ml-2" @click="fullScreen = false">
                  full-screen-off
                </EButton>
              </div>
            </template>
          </div>

          <slot />
        </div>
      </portal>
    </div>
  </div>
</template>

<script>
import { WithTime } from '@/mixins';
import mixin from './mixin';
import { TITLE_KEY } from './constants';

// components
import DataTitle from './DataTitle.vue';

export default {
  name: 'DataContainer',
  components: { DataTitle },
  mixins: [WithTime, mixin],
  data() {
    return {
      fullScreen: false,
    };
  },
  computed: {
    portalTargetName() {
      return `${_.kebabCase(this.manifest[TITLE_KEY])}-portal-target`;
    },

    dateRange() {
      const { asDate } = this.$time.format;

      return `${asDate(this.from)} / ${asDate(this.to)}`;
    },
  },
  watch: {
    portalTargetName: {
      handler() {
        this.$nextTick(() => this.$emit('ready'));
      },
      immediate: true,
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$border-width: 1px

// - header
.data-container__header > div:first-child
  display: flex

  border-bottom-style: solid
  border-bottom-width: $border-width

// - body
.data-container__body
  position: relative

.data-container__full-screen-body
  background-color: var(--c-background)
  overflow: hidden

  +p-absolute($z-index-overlay, 2.5%)

+media-down(md)
  .data-container__header > div:first-child
    flex-direction: column
    align-items: flex-start

    & > .e-text:last-child
      margin-top: $spacing-2

+media-up(md)
  .data-container__header > div:first-child
    flex-direction: row
    align-items: center

    & > .e-text:last-child
      margin-left: $spacing-2

// color
// - header
.data-container__header > div:first-child
  border-bottom-color: var(--c-gray-1)
</style>
