import i18n from '@/i18n';

type Preset = -1 | 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8;

type PresetOption = {
  text: string;
  value: Preset;

  disabled?: boolean;
  primary?: boolean;
};

const I18N_PATH = 'c.date-range-picker';

export const CUSTOM_PRESET: PresetOption = {
  text: i18n.tc(`${I18N_PATH}.custom`),
  value: -1,

  disabled: true,
  primary: true,
};

export const PRESETS: Readonly<PresetOption[]> = Object.freeze([
  { text: i18n.tc(`${I18N_PATH}.today`), value: 0 },
  { text: i18n.tc(`${I18N_PATH}.yesterday`), value: 1 },
  { text: i18n.tc(`${I18N_PATH}.this-week`), value: 2 },
  { text: i18n.tc(`${I18N_PATH}.last-week`), value: 5 },
  { text: i18n.tc(`${I18N_PATH}.this-month`), value: 3 },
  { text: i18n.tc(`${I18N_PATH}.last-month`), value: 6 },
  { text: i18n.tc(`${I18N_PATH}.this-year`), value: 4 },
  { text: i18n.tc(`${I18N_PATH}.last-year`), value: 7 },
  { text: i18n.tc(`${I18N_PATH}.beginning-of-time`), value: 8, primary: true },
]);
