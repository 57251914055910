<template>
  <div class="unwrapped-select">
    <EButton
      v-for="(option, index) in options"
      :key="option.value"
      v-bind="buttonBind(index)"
      :class="optionClassName(index)"
      @click="onOptionClick(option)"
    >
      {{ option.text }}
    </EButton>
  </div>
</template>

<script>
export default {
  name: 'UnwrappedSelect',
  props: {
    value: {
      type: Number,
      default: undefined,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  methods: {
    buttonBind(index) {
      const bind = {
        outlined: true,
        md: true,
        selected: this.options[index].value === this.value,
        disabled: this.options[index].disabled,
      };

      return Object.assign(bind, this.$attrs);
    },

    onOptionClick(option) {
      this.$emit('input', option.value);
    },

    optionClassName(index) {
      const option = this.options[index];
      const className = 'unwrapped-select__option';

      return [className, { [`${className}--primary`]: option.primary }];
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$max-col-width: auto
$min-col-width: 130px

.unwrapped-select
  display: grid
  grid-template-columns: repeat(2, minmax($min-col-width, $max-col-width))
  grid-auto-rows: 1fr
  column-gap: $spacing-3
  row-gap: $spacing-2

.unwrapped-select__option
  --f-size: #{$f-size-xs}
  --f-weight: #{$f-weight-medium}

  &--primary
    grid-column: 1 / 3
</style>
