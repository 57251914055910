<template>
  <OpenableCard class="date-range-picker date-range-picker--desktop">
    <template v-slot:activator>
      <div class="date-range-picker__activator">
        <icon :size="20" class="mr-2">calendar</icon>
        <DateRangePickerDateRange
          :date-range="{ from: timeFrom, to: timeTo }"
        />
        <icon class="ml-3">chevron-down</icon>
      </div>
    </template>

    <template v-slot:body="{ close }">
      <div class="date-range-picker__header">
        <icon :size="20" class="mr-2">calendar</icon>
        <t semibold>{{ $t('c.date-range-picker.date-range') }}</t>
        <div class="spacer" />
        <DateRangePickerDateRange :date-range="{ from, to }" class="ml-2" />
      </div>

      <div class="date-range-picker__body">
        <div class="date-range-picker__presets">
          <UnwrappedSelect
            :value="preset"
            :options="presetsAsOptions"
            @input="onPresetInput"
          />
        </div>

        <div class="date-range-picker__calendar">
          <EInput
            :value="{ from, to }"
            type="date-range"
            :min-date="minDate"
            :max-date="maxDate"
            :from-label="$t('c.date-range-picker.from')"
            :to-label="$t('c.date-range-picker.to')"
            :locale="locale"
            nav-visibility="hidden"
            hide-details
            inline
            @input="onDateRangeInput"
          />
        </div>
      </div>

      <div class="date-range-picker__footer">
        <EButton md @click="onClick(close)">{{ $t('g.cancel') }}</EButton>

        <EButton filled md :disabled="!canApply" @click="onClick(close, true)">
          {{ $t('c.date-range-picker.apply') }}
        </EButton>
      </div>
    </template>
  </OpenableCard>
</template>

<script>
import mixin from './mixin';

// components
import DateRangePickerDateRange from './DateRangePickerDateRange.vue';
import OpenableCard from '@/components/ui/OpenableCard.vue';
import UnwrappedSelect from '@/components/ds/UnwrappedSelect.vue';

export default {
  name: 'DateRangePickerDesktop',
  components: { DateRangePickerDateRange, OpenableCard, UnwrappedSelect },
  mixins: [mixin],
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
.date-range-picker__header
  +d-flex-r(center, center)

.date-range-picker__footer
  & > .e-button
    margin-left: $spacing-3

  & > .e-button:first-child
    margin-left: 0

+media-up(md)
  $border-radius: 4px // FIX: move to ds
  $card-border-radius: 16px // FIX: move to ds

  $activator-height: 40px

  $padding: $spacing-4

  .date-range-picker--desktop > .card
    --border-radius: #{$card-border-radius}

  .date-range-picker__activator
    position: relative

    +button
    +d-flex-r(center)
    +h-rounded($activator-height, true)

    & > *
      position: relative

    // FIX: move
    +on-hover
      &::before
        opacity: 1

  .date-range-picker__activator::before
    content: ''

    border-radius: ($activator-height - 8px) / 2 // FIX
    box-shadow: $elevation-1
    opacity: 0

    transition: opacity $duration-normal $ease-in-out-sine

    +p-absolute(0, 4px) // FIX

  .date-range-picker__header
    padding: $padding

  .date-range-picker__body
    display: grid
    grid-template-areas: "presets calendar"
    gap: $padding

    +h-padding($padding)

  .date-range-picker__presets
    grid-area: presets

  .date-range-picker__calendar
    grid-area: calendar

  .date-range-picker__footer
    padding: $padding

    +d-flex-r(null, flex-end)

// color
+media-up(md)
  .date-range-picker__activator
    background-color: #F4F4F4 // FIX

  .date-range-picker__activator::before
    background-color: var(--c-background)
</style>
