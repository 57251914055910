import { mapActions, mapState } from 'vuex';
import { CUSTOM_PRESET, PRESETS } from './constants';
import { presetToDateRange } from './helpers';

export default {
  props: {
    dimensionId: { type: String, required: true },

    minDate: { type: Object, default: null },
    maxDate: { type: Object, default: null },

    defaultPreset: { type: Number, default: 6 },
  },
  data() {
    return {
      presets: PRESETS,
    };
  },
  computed: {
    ...mapState(['locale']),
    ...mapState('time', {
      timeFrom(state) {
        return state.dimensions[this.dimensionId].from;
      },
      timeTo(state) {
        return state.dimensions[this.dimensionId].to;
      },

      // buffer
      from(state) {
        return state.dimensions[this.dimensionId].buffer.from;
      },
      to(state) {
        return state.dimensions[this.dimensionId].buffer.to;
      },

      preset(state) {
        return state.dimensions[this.dimensionId].preset;
      },

      isSetByUser(state) {
        return state.dimensions[this.dimensionId].isSetByUser;
      },
    }),

    isSynced() {
      return this.$store.getters['time/isSynced'](this.dimensionId);
    },

    timeReady() {
      return this.$store.getters['time/ready'](this.dimensionId);
    },

    dateRangeAsString() {
      if (!this.localTimeReady) return;

      const { from, to } = this;

      return this.$time.format.asDateRange({ from, to });
    },
    allPresetsAsStrings() {
      return _.map(PRESETS, ({ value: preset }) =>
        this.$time.format.asDateRange(this.presetToDateRange(preset))
      );
    },
    daysCount() {
      if (!this.localTimeReady) return;

      const { from, to } = this;

      return Math.ceil(
        this.$time.getDateRangeDaysCount({ from, to }, 2 /* days */)
      );
    },

    presetsAsOptions() {
      if (!this.timeReady) return;

      return [
        ...PRESETS.map(preset => ({
          ...preset,

          disabled: !this.isValidDateRange(
            this.presetToDateRange(preset.value)
          ),
        })),
        CUSTOM_PRESET,
      ];
    },

    canApply() {
      const { from, to } = this;

      return !this.isSynced && this.isValidDateRange({ from, to });
    },

    localTimeReady() {
      return this.from && this.to;
    },
    ready() {
      return this.timeReady;
    },
  },
  watch: {
    dimensionId(current, previous) {
      if (current === previous) return;

      this.setup();
    },
  },
  created() {
    this.setup();
  },
  methods: {
    ...mapActions('time', ['setDimension', 'deleteDimension']),

    setDateRange(payload) {
      this.$store.dispatch('time/setDateRange', {
        id: this.dimensionId,

        ...payload,
      });
    },
    setPreset(payload) {
      this.$store.dispatch('time/setPreset', {
        id: this.dimensionId,

        ...payload,
      });
    },
    sync(payload) {
      this.$store.dispatch('time/sync', {
        id: this.dimensionId,

        ...payload,
      });
    },

    presetToDateRange(preset, payload) {
      // method to add <this.minDate> and <this.maxDate> to payload by default

      return presetToDateRange(preset, {
        minDate: this.minDate,
        maxDate: this.maxDate,

        ...payload,
      });
    },

    async setup() {
      this.setDimension({
        id: this.dimensionId,
        minFrom: this.minDate,
        maxTo: this.maxDate,
      });

      if (this.isSetByUser) return;

      const preset = this.defaultPreset;
      let pivot;

      // building <pivot> from <preset>
      if (preset !== 8 /* beginning-of-time */) {
        const now = moment();
        const now_ = this.setDateToMinMax(now);

        pivot = now === now_ ? now.endOf('day') : now_.endOf('day');
      }

      const dateRange = this.setDateRangeToMinMax(
        this.presetToDateRange(preset, { pivot })
      );

      this.setDateRange({ ...dateRange });
      this.setPreset({ preset });
      this.sync();
    },
    teardown() {
      this.deleteDimension({ id: this.dimensionId });
    },

    setDateToMinMax(date) {
      if (this.minDate) {
        const minDate = moment(this.minDate).startOf('day');

        if (minDate.diff(moment(date).startOf('day')) > 0) return minDate;
      }

      if (this.maxDate) {
        const maxDate = moment(this.maxDate).endOf('day');

        if (maxDate.diff(moment(date).endOf('day')) < 0) return maxDate;
      }

      return date;
    },
    setDateRangeToMinMax({ from, to }) {
      return {
        from: from ? this.setDateToMinMax(from).startOf('day') : null,
        to: to ? this.setDateToMinMax(to).endOf('day') : null,
      };
    },
    setDateRange_(dateRange, shouldSyncPreset) {
      dateRange = this.setDateRangeToMinMax(dateRange);
      this.setDateRange({ ...dateRange });

      if (!shouldSyncPreset) return;

      const index = _.indexOf(this.allPresetsAsStrings, this.dateRangeAsString);
      const preset =
        index !== -1 ? this.presets[index].value : CUSTOM_PRESET.value;

      this.setPreset({ preset });
    },
    setDateRangeToPreset(preset) {
      if (preset === CUSTOM_PRESET.value) return;

      this.setPreset({ preset });
      this.setDateRange_(this.presetToDateRange(preset), false);
    },

    isValidDateRange({ from, to }) {
      if (!from || !to) return;

      // copy of <from> and <to> to avoid <startOf> and <endOf> mutations
      from = moment(from).startOf('day');
      to = moment(to).endOf('day');

      return (
        (!this.minDate ||
          // are both before the minimum date?
          !(this.minDate.diff(from) > 0 && this.minDate.diff(to) > 0)) &&
        (!this.maxDate ||
          // are both after the maximum date?
          !(this.maxDate.diff(from) < 0 && this.maxDate.diff(to) < 0)) &&
        to.diff(from) >= 0
      );
    },

    onDateRangeInput(dateRange) {
      this.setDateRange_(dateRange, true);
    },
    onPresetInput(preset) {
      this.setDateRangeToPreset(preset);
    },

    onClick(close, apply = false) {
      if (apply) this.sync({ isSetByUser: true });

      close();
    },
  },
};
