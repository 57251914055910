<template>
  <div class="date-range-picker date-range-picker--mobile">
    <portal v-if="ready" to="bottom-drawer-header">
      <div class="date-range-picker__header">
        <DateRangePickerDateRange :date-range="{ from, to }" />
      </div>
    </portal>

    <portal v-if="ready" to="bottom-drawer-body">
      <div class="date-range-picker__body">
        <div class="date-range-picker__body-body">
          <transition name="fade-normal" mode="out-in">
            <div
              v-if="!isShowingCalendar"
              key="presets"
              class="date-range-picker__presets"
            >
              <UnwrappedSelect
                :value="preset"
                :options="presetsAsOptions"
                lg
                class="mb-4"
                @input="onPresetInput"
              />
            </div>

            <div v-else key="calendar" class="date-range-picker__calendar">
              <EInput
                :value="{ from, to }"
                type="date-range"
                :min-date="minDate"
                :max-date="maxDate"
                :from-label="$t('c.date-range-picker.from')"
                :to-label="$t('c.date-range-picker.to')"
                :locale="locale"
                nav-visibility="hidden"
                hide-details
                inline
                is-expanded
                @input="onDateRangeInput"
              />
            </div>
          </transition>

          <EButton lg @click="onToggleClick">
            <template v-if="isShowingCalendar">
              <icon>calendar</icon> {{ $t('c.date-range-picker.use-presets') }}
            </template>

            <template v-else>
              <icon>calendar</icon> {{ $t('c.date-range-picker.use-calendar') }}
            </template>
          </EButton>
        </div>

        <div class="date-range-picker__footer">
          <EButton lg @click="onClick(closeBottomDrawer)">
            {{ $t('g.cancel') }}
          </EButton>

          <EButton
            filled
            lg
            :disabled="!canApply"
            @click="onClick(closeBottomDrawer, true)"
          >
            {{ $t('c.date-range-picker.apply') }}
          </EButton>
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import mixin from './mixin';

// components
import DateRangePickerDateRange from './DateRangePickerDateRange.vue';
import UnwrappedSelect from '@/components/ds/UnwrappedSelect.vue';

export default {
  name: 'DateRangePicker',
  components: { DateRangePickerDateRange, UnwrappedSelect },
  mixins: [mixin],
  data() {
    return {
      isShowingCalendar: false,
    };
  },
  methods: {
    ...mapActions('ui', ['closeBottomDrawer']),

    onToggleClick() {
      this.isShowingCalendar = !this.isShowingCalendar;
    },
  },
};
</script>

<style lang="sass">
@import odd-ds/dist/lib/index.sass

// base
$activator-height: 40px

+media-down(md)
  $h-padding: 32px // FIX

  .date-range-picker__header
    position: relative

    +d-flex-r(center)
    +h-rounded($activator-height, true)

    & > *
      position: relative

  .date-range-picker__header::before
    content: ''

    border-radius: ($activator-height - 8px) / 2 // FIX
    box-shadow: $elevation-1
    opacity: 1

    transition: opacity $duration-normal $ease-in-out-sine

    +p-absolute(0, 4px) // FIX

  .date-range-picker__body-body
    +d-flex-c(center)

    & > *
      width: 100%

  .date-range-picker__footer
    margin-top: $spacing-4

    +d-flex-r(center, center)

// color
+media-down(md)
  .date-range-picker__header
    background-color: #F4F4F4 // FIX

  .date-range-picker__header::before
    background-color: var(--c-background)
</style>
